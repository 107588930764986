import request from "../utils/request";

// 注册
export function adminLogin(params) {
  return request({
    url: "63acee8db3e62",
    method: "get",
    params,
  });
}

// 登录
export function userLogin(params) {
  return request({
    url: "63aceeb60d784",
    method: "get",
    params,
  });
}

// 在线询价查询
export function searchonline(params) {
  return request({
    url: "664ea524a631c",
    method: "get",
    params,
  });
}

// 获取手机验证码
export function getcode(params) {
  return request({
    url: "63aceea65e4e3",
    method: "get",
    params,
  });
}

// 忘记密码
export function retrieve(params) {
  return request({
    url: "63aceec6b33ae",
    method: "get",
    params,
  });
}

// 轮播列表
export function carouselList(params) {
  return request({
    url: "6523c8e07c9bb",
    method: "get",
    params,
  });
}

//获取箱规
export function typeList(params) {
  return request({
    url: "63acef09df230",
    method: "get",
    params,
  });
}

//获取港口
export function portList(params) {
  return request({
    url: "664ed16287a0f",
    method: "get",
    params,
  });
}

//城市列表
export function cityList(params) {
  return request({
    url: "6653f8174484f",
    method: "get",
    params,
  });
}

//获取收费标准
export function rates(params) {
  return request({
    url: "664d96e64c045",
    method: "get",
    params,
  });
}

//获取询价记录
export function searchRecord(params) {
  return request({
    url: "664ea50a0106e",
    method: "get",
    params,
  });
}

//获取起运港
export function startport(params) {
  return request({
    url: "63aceee9afcff",
    method: "get",
    params,
  });
}

//获取国家列表
export function countryList(params) {
  return request({
    url: "63aceed980390",
    method: "get",
    params,
  });
}

//获取订单列表
export function orderList(params) {
  return request({
    url: "63acef4f3eba6",
    method: "get",
    params,
  });
}

//获取地址类型
export function addresstype(params) {
  return request({
    url: "650d0c0024e4a",
    method: "get",
    params,
  });
}

//用户询价
export function usersearch(params) {
  return request({
    url: "63acef2c13062",
    method: "get",
    params,
  });
}

//获取新闻列表
export function newslist(params) {
  return request({
    url: "63acf0b05442a",
    method: "get",
    params,
  });
}

//获取新闻详情
export function newsdec(params) {
  return request({
    url: "63acf0c0c6428",
    method: "get",
    params,
  });
}

// 新闻资讯切换
export function changenews(params) {
  return request({
    url: "666a535607148",
    method: "get",
    params,
  });
}

// 获取个人信息
export function userinfo(params) {
  return request({
    url: "63acefc675dc2",
    method: "get",
    params,
  });
}

// 验证手机号码
export function checkphone(params) {
  return request({
    url: "63acefd73f681",
    method: "get",
    params,
  });
}

// 绑定新手机号
export function changephone(params) {
  return request({
    url: "63acefe773a29",
    method: "get",
    params,
  });
}

// 修改密码
export function changenewpassword(params) {
  return request({
    url: "63acf0075baa9",
    method: "get",
    params,
  });
}

// 修改个人信息
export function changeuserinfo(params) {
  return request({
    url: "63aceff511eca",
    method: "get",
    params,
  });
}

// 邀请记录
export function inviterecord(params) {
  return request({
    url: "6568475daaace",
    method: "get",
    params,
  });
}

//返佣记录
export function rebaterecord(params) {
  return request({
    url: "651543471b73c",
    method: "get",
    params,
  });
}

//提现记录
export function withdrawalRecord(params) {
  return request({
    url: "651513426660d",
    method: "get",
    params,
  });
}

// 提现申请详情
export function withdrawalDec(params) {
  return request({
    url: "6515159da8774",
    method: "get",
    params,
  });
}

// 提现申请
export function withdrawal(params) {
  return request({
    url: "6514f41596095",
    method: "get",
    params,
  });
}

// 电话客服
export function phoneservice(params) {
  return request({
    url: "63acfacd8de6a",
    method: "get",
    params,
  });
}

//用户协议
export function useragreement(params) {
  return request({
    url: "63acedbd6b690",
    method: "get",
    params,
  });
}

//订单详情
export function orderDec(params) {
  return request({
    url: "63acef5bccbfe",
    method: "get",
    params,
  });
}

// 取消订单
export function cencelorder(params) {
  return request({
    url: "63acefaaa9f64",
    method: "get",
    params,
  });
}

// 车队报价
export function priceList(params) {
  return request({
    url: "63acef75deef9",
    method: "get",
    params,
  });
}

// 报价详情
export function priceDec(params) {
  return request({
    url: "63acef99c8f9f",
    method: "get",
    params,
  });
}

//接受报价
export function agreeprice(params) {
  return request({
    url: "63acef3ba7adf",
    method: "get",
    params,
  });
}

//用户协议
export function userAgreement(params) {
  return request({
    url: "63acedbd6b690",
    method: "get",
    params,
  });
}

