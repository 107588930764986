import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "@/components/rem";
import "./utils/scroll.js";
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);

import VueI18n from "../node_modules/vue-i18n";
import locale from "element-ui/lib/locale/lang/en"; // lang i18n  引入中文 用于解决分页组件是英文的问题
import Element from "element-ui";
Vue.use(VueI18n); // 通过插件的形式挂载
const i18n = new VueI18n({
  locale: sessionStorage.getItem("cut") || "zh-CN", // 语言标识
  messages: {
    "zh-CN": require("./components/lang/zh"), // 中文语言包
    "en-US": require("./components/lang/en"), // 英文语言包
  },
});
if (sessionStorage.getItem("cut") == "zh-CN") {
  Vue.use(Element);
} else {
  Vue.use(Element, { locale });
}

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
