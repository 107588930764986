export const m = {
  girl: '发货信息',
  door: '整箱到门',
  port: '整箱到港',
  carload: '拼箱/零担/整车',
  date: '发布日期',
  nation: '起运国家',
  haven: '起运港口',
  take: '收货信息',
  destination: '目的地国家',
  postcode: '目的地邮编',
  week: '目的港/目的地',
  address: '目的地地址',
  note: '订单备注',
  cargo: '货物信息',
  containers: '集装箱数量',
  specification: '集装箱规格',
  rough: '毛重（单箱）',
  add: '添加集装箱',
  read: '我已同意并阅读',
  agreement: '《服务协议》',
  inquiry: '立即询价',
  style: '地址类型',
  departure: '起运地地址',
  description: '货物名称',
  quantity: '货物数量',
  palletizing: '打托数量',
  cardboard: '包装类型',
  value: '($)货值',
  basis: '单品重量',
  long: '长',
  wide: '宽',
  high: '高',
  danger: '是否是危险物品',
  gross: '总重量',
  volume: '总体积',
  reference: '订单编号',
  status: '订单状态',
  search: '搜索',
  reset: '重置',
  operation: '操作',
  essential: '基本信息',
  accept: '我接受的报价',
  time: '发布时间',
  cancellation: '取消订单',
  relevant: '相关报价',
  customer: '专属客服',
  name: '客服姓名',
  customerservice: '客服',
  mail: '联系邮箱',
  company: '企业信息',
  firm: '企业名称',
  business: '企业类型',
  includes: '总费用包括',
  united: '助力全球贸易',
  logistics: 'SPEEDYETA你的全球合作伙伴SPEEDYETA.COM全球100多个国家的整箱/拼箱到港，到门服务的免费询价平台，迅捷，准确，优惠，是我们平台的服务宗旨，你安心拓展你的全球业务，我们保障你的物流。',
  query: '确认查询',
  completeRegistration: '完成注册',
  RegistrationTip: '代理注册需要平台进行验证，请联系平台客服确认操作。',
  overseas: '准备拓展海外市场？',
  global: '轻松走向全球',
  flow: '我们将简化您的物流，帮助您没有后顾之忧地进入这个充满机遇的世界。无论业务规模大小，特为都能让您的物流变得简单。',
  about: '关于送哪趣',
  aboutus: '关于我们',
  oneself: '致力于为客户提供高服务、高品质、高标准的一站式标准化智能国际物流解决方案。',
  understand: '了解更多',
  discover: '了解我们的端到端服务',
  transport: '您想以港口到港口还是仓库到仓库方式运送您的货物？在送哪趣SPEEDYETA，都可帮您稳妥安排各项货运事宜。我们提供综合的物流解决方案，从清关服务到货物运输。',
  todoor: '到门服务',
  supplement: '到门服务是海运的重要补充，通过送哪趣SPEEDYETA,您将获得超过百家车队，千台的运力给你提供送货到门服务，你只需要在平台上确认需要服务，剩下的全部交给我们处理。',
  sea: '海运服务',
  process: '在美国和加拿大近20个基本港和50多个内陆点，送哪趣SPEEDYETA能全部提供全方位的服务，我们不仅能为你能够在你的业务全球互联，并且在此过程中简化你的端到端的物流。',
  clearance: '清关服务',
  full: '使用送哪趣SPEEDYEAT的报关清关服务，能使您的货物更加便捷，你不必费吹灰之力即可安心完成。无论是想进口还是出口清关，我们都能提供全程服务。',
  timely: '及时运价',
  situation: '机会出现时，请牢牢把握，让市场为你所用，我们关注市场价格走势，提供基于当前供需情况的动态运价。',
  perspectives: '新闻和视角',
  latest: '最新新闻：行业最新资讯只需点击一下就能看到!',
  shipment: '第一次出运',
  shipping: '让我们帮助您做好出运准备',
  rules: '繁杂的航运规则可能会让人不知所措，并且各种信息比比皆是。由此开始——让我们为您提供一些首次装运的指导。',
  reserved: '2023 复星国际物流（青岛）有限公司 版权所有 鲁ICP备2022040022号-1',
  industry: '行业资讯',
  details: '新闻详情',
  platform: '送哪趣 · 专业的海外运输物流行业询价平台',
  transparent: '价格透明',
  system: '完美的物流体系',
  sales: '专业售前售后服务',
  operate: '送哪趣作为专业的海外运输物流行业询价平台，凭借多年丰富的行业经验，以强大的网络资源为依托、以精准的行业数据为基准、以高效专业的服务能力为目标，通过在线实时报价、 智能撮合匹配等多种方式，满足全球物流客户在海外物流方面对询价方面的需求。目前已与中国及世界各地数千家海外物流企业建立了长期合作关系。送哪趣凭借自身不断创新和发展的动力， 正为越来越多有出海意向和需求的客户提供着专业、高效、优质的海外运输服务。',
  officially: '送哪趣是复星国际物流（青岛）有限公司旗下海外物流的电商平台。送哪趣于2022年1月正式上线运营，凭借对全球贸易市场快速发展和客户需求变化趋势的敏锐把握， 以强大而稳定的核心资源与卓越品质为依托，以高效专业的服务能力为目标，通过在线实时报价、智能撮合匹配等多种方式为全球贸易客户提供一站式海外货运服务。',
  guidance: '繁杂的航运规则可能会让人不知所措，并且各种信息比比皆是。由此开始——让我们为您提供一些首次装运的指导。',
  site: '地址',
  phone: '电话',
  facsimile: '传真',
  shanghai: 'FULLTRANS 上海',
  building: '上海市杨浦区昆明路518号北美大厦A座1901室，200082',
  qingdao: 'FULLTRANS 青岛',
  road: '山东省青岛市市北区龙城路30号万达广场住宅3号楼第一单元2004室，266034',
  nanjing: 'FULLTRANS 南京',
  pukou: '中国江苏省南京市浦口区星火路智芯科技大厦15号218室',
  lianyungang: 'FULLTRANS 连云港',
  block: '江苏省连云港市连云区中华西路80号中华豪庭A座1601室',
  ningbo: 'FULLTRANS 宁波',
  west: '中国浙江省宁波市海曙区中山西路恒隆中心1309室',
  tianjin: 'FULLTRANS 天津',
  companyinfo: '填写企业信息',
  heping: '中国天津市和平区贵州路4号龙通大厦1604室。300051',
  zhangjiagang: 'FULLTRANS 张家港',
  renmin: '中国江苏省张家港市杨舍镇人民路49号金城大厦509室215600',
  changzhou: 'FULLTRANS 常州',
  taihu: '中国江苏省常州市新北区太湖东路101-1号昌发商业广场5-2508。',
  reason: '取消原因',
  cancel: '取消',
  verify: '确认',
  standard: '额外费用收费标准',
  cost: '总费用',
  fleet: '车队名称',
  type: '车队类型',
  freight: '总运费',
  controls: '操作',
  information: '车队信息',
  offer: '接受报价',
  finally: '最终报价',
  enquiry: '询价中心',
  safety: '安全中心',
  account: '账户信息',
  head: '个人头像',
  personage: '个人信息',
  mailbox: '电子邮箱',
  call: '手机号码',
  amend: '修改',
  save: '保存',
  mobile: '修改手机号',
  auth: '验证码',
  security: '获取验证码',
  one: '下一步',
  sent: '已发送(',
  bound: '绑定新手机号',
  ensure: '确定',
  standing: '身份验证',
  attempts: '重置密码',
  again: '再次确认',
  once: '登录密码',
  friends: '邀请好友',
  bids: '邀请记录',
  link: '邀请链接',
  copy: '复制',
  register: '注册时间',
  pet: '用户昵称',
  rebate: '邀请返佣',
  fyhelper: '返佣记录',
  grand: '累计返佣',
  remaining: '账户余额',
  promptly: '立即提现',
  buxify: '返佣时间',
  earnings: '返佣金额',
  apply: '提现申请',
  how: '注：',
  know: '海外账户不知如何操作可联系平台处理',
  bank: '银行卡账户',
  cardholder: '持卡人姓名',
  deposit: '开户行',
  withdrawal: '提现金额',
  may: '可提现金额',
  approve: '提现详情',
  need: '提现时间',
  telephone: '电话客服',
  telephone1: '客服热线',
  loadmore: '查看更多',
  terrace: '平台协议',
  paas: '用户协议',
  service: '服务协议',
  hint: '提示',
  want: '您确定退出账号登录吗？',
  picture: '货物图片',
  salaries: '新增货物',
  license: '上传营业执照',
  electronic: '上传电子协议',
  balance: '余额提现',
  change: '修改密码',
  record: '提现记录',
  condition: '提现状态',
  examine: '查看',
  rejection: '驳回原因',
  orderList: '订单列表',
  shown: '订单详情',
  buying: '取消时间',
  vehicle: '整车车队',
  forwarder: '货代/零担/整车',
  enroll: '注册',
  ringup: '登录',
  homepage: '主页',
  workbench: '工作台',
  provider: '供应商注册',
  airline: '报价详情',
  cannot: '接受报价后不可查看或接受其他车队报价，您确定还要接受此报价吗？',
  use: '使用送哪趣',
  interesting: '联系送哪趣',
  freefone: '免费电话',
  kefuaobicom: '客服邮箱',
  create: '注册账号',
  password: '确认密码',
  away: '立即注册',
  agency: '注册类型',
  individuals: '企业及个人',

  agent: '代理商',
  young: '已有账号，',
  database: '立即登录',
  find: '找回密码',
  welcome: '欢迎登入',
  proceed: 'SPEEDYETA询价系统',
  yet: '还没有账户？',
  when: '注册！',
  already: '用户账号',
  asked: '《用户协议》',
  forget: '忘记密码',
  navigate: '首页',
  connect: '联系我们',
  yesterday: '订单查询',
  logDown: '退出系统',
  pending: '待处理',
  quotation: '报价中',
  confirmed: '待确认',
  underway: '进行中',
  settle: '待结算',
  stocks: '已完成',
  canceled: '已取消',
  players: '选择日期时间',
  keli: '请选择',
  give: '请输入',
  solstice: '至',
  start: '开始日期',
  deadline: '结束日期',
  reasonfor: '请输入取消原因',
  enterAccount: '请输入银行卡账户',
  enterCard: '请输入持卡人姓名',
  enterMoney: '请输入提现金额',
  enterBank: '请输入开户行名称',
  enterPass: '请输入登录密码',
  enterPhone: '请输入手机号',
  enterCode: '请输入验证码',
  twice: '请二次输入密码',
  repetition: '不能重复获取',
  enterContent: '请输入内容',
  enterstart: '请选择发布日期',
  enternation: '请选择起运国家',
  enterport: '请选择起运港口',
  entergoal: '请选择目的国家',
  enterzip: '请选择目的地邮编',
  enterpostal: '请选择目的港/目的地',
  enterSite: '请输入目的地地址',
  entercontainer: '请输入集装箱数量',
  enterspec: '请选择集装箱规格',
  entergross: '请输入毛重',
  enterunit: '请选择计量单位',
  enterread: '请先同意并阅读《服务协议》',
  submit: '提交成功',
  enterName: '请输入货物名称',
  enterNum: '请输入货物数量',
  enterpalletizing: '请输入打托数量',
  enterpack: '请选择包装类型',
  entervalue: '请输入货值',
  tailorism: '请输入单品重量',
  enterlong: '请输入长',
  enterwide: '请输入宽',
  enterhight: '请输入高',
  enterrisk: '请选择是否是危险物品',
  enterfirm: '请返回上一页面输入企业名称',
  entermail: '请返回上一页面输入电子邮箱',
  successfully: '注册成功',
  enterbusiness: '请输入企业名称',
  enteremail: '请输入电子邮箱',
  enterlogin: '登录成功',
  review: '审核中',
  withdrawn: '已提现',
  rejected: '已驳回',
  funds: '余额不足',
  cash: '提现成功',
  utton: '重置成功',
  changed: '修改密码成功',
  preservation: '保存成功',
  federal: '上传头像图片大小不能超过2MB',
  brushing: '已复制',
  gives: '绑定成功',
  deletable: '不可删除',
  initial: '请选择地址类型',
  yonghu: '请输入起运地地址',
  accepted: '已接受',
  pin: '请输入密码',
  back: '返回',
  USA: '美国',
  Canada: '加拿大',
  Destination: '目的地',
  Country: '国家',
  WeChat: '微信',
  nowprice: '实时报价',
  onlineprice: '在线报价',
  searchrecord: '询价记录',
  rengongprice: '人工报价',
  myorder: '我的订单',
  continuetext: '即将跳转到订单查询地址, 是否继续?',
  hometext1: '您专心拓展美加业务我们保障您的后端服务',
  hometext2: '商界风云变幻，改变商业运作模式正当时。将您的物流服务从线下转移到线上',
  hometext3: '通过送哪趣SPEEDYETA，您可以轻松在线询价，跟踪和安排您的货物',
  searchcarnow: '查询卡车实时运价',
  startcity: '起运城市(邮编)',
  startonecity: '起运城市',
  endonecity: '送达城市',
  endcity: '送达城市(邮编)',
  endport: '卸货港/卸货地',
  getnowprice: '获取实时运价',
  searchnowprice: '查询实时运价',
  selectstartcity: '请选择起运城市',
  selectendCity: '请选择送达城市',
  selectendport: '请选择卸货港/卸货地',
  last: '上一篇',
  next: '下一步',
  nextone: '下一篇',
  newnew: '最新资讯',
  hotnew: '热门资讯',
  nomorelast: '没有上一篇啦',
  nomorenext: '没有下一篇啦',
  earn: '收费标准',
  collect: '收藏',
  allprice: '拖车费合计',
  oneendport: '卸货港',
  carmi: '卡车里程',
  searchhistory: '查询记录',
  searchtime: '查询时间',
  titype: '提货类型',
  ticode: '提货邮编',
  selecttutype: '请选择提货类型',
  selectsongtype: '请选择送货类型',
  inputticode: '请输入提货邮编',
  songtype: '送货类型',
  addgoodsinfo: '请添加货物信息',
  carteamprice: '车队报价',
  acceptprice: '接受报价成功',
  orderinfo: '订单信息',
  markcontent: '备注内容',
  cancelsucess: '取消成功',
  yingyeagreement: '营业协议',
  acpass: '账号密码',
  balancedec: '余额明细',
  binded: '已绑定:',
  laststep: '上一步',
  changephone: '修改手机号成功'
}
