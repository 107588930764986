<template>
  <div>
    <app-header @show="handleChildEvent" @showstatus="showstatus"></app-header>
    <app-main
      :show="show"
      @show="handleChildEvent"
      @showstatus="showstatus"
      :status="status"
    ></app-main>
    <router-view
      @show="handleChildEvent"
      @showstatus="showstatus"
    ></router-view>
    <app-kefu></app-kefu>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader'
import AppMain from './components/AppMain'
import AppFooter from './components/AppFooter'
import AppKefu from './components/AppKefu'
export default {
  components: { AppHeader, AppMain, AppFooter, AppKefu},
  data () {
    return {
      show: false,
      status: 1
    }
  },
  methods: {
    handleChildEvent (payload) {
      this.show = payload
    },
    showstatus (payload) {
      this.show = payload[0]
      this.status = payload[1]
    }
  }
}
</script>

<style scoped>
/* 头部样式 */
.header {
  position: fixed;
  background-color: #fff;
  min-height: 68px;
  z-index: 99;
  top: 0;
}
.container {
  background: #f9f9f9;
  padding-top: 68px;
  box-sizing: border-box;
  min-height: calc(100vh - 70px);
}
/* 左侧样式 */
.navbar {
  position: absolute;
  width: 200px;
  top: 50px; /* 距离上面50像素 */
  left: 0px;
  bottom: 0px;
  overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
  background-color: #545c64;
}

/* 主区域 */
.main {
  position: fixed;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
  z-index: 100;
}
</style>
