<template>
  <div class="servicebox" @click="toservice">
    <img src="@/assets/kefu.jpg" alt="" />
    <div class="servicetext">{{ cutList.customerservice }}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    cutList () {
      return this.$t('m')
    }
  },
  methods: {
    toservice () {
      window.open('https://kf.speedyeta.com/kefu.php?a=6168', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
 .servicebox {
    position: fixed;
    right: 0;
    top: 50%;
    width: 6rem;
    background: #ffffff;
    border-radius: 4px 0px 4px 4px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    cursor: pointer;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    .servicetext {
      margin-top: 0.5rem;
      font-size: 1rem;
      color: #666666;
      text-align: center;
      font-weight: bolder;
    }
    img {
      width: 5rem;
      height: auto;
    }
  }
</style>
