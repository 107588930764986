<template>
  <div class="mainFooter">
    <div class="mainInfo" v-if="language == 1">
      <div class="leftInfo">
        <div class="font-bold leftInfoTitle">联系方式</div>
        <div class="flex align-center" style="margin: 1rem 0">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAABGpJREFUWEfNmGmsnWMUhZ8lpkQMIVSiNUT5gUpEXVFqbFBqlvCTSrSGRhUxNoSa0kFFTf1REhGamCmNIEIQqiQqEkJQQ0w1VYxlybp5z/Wdc8+59/SccxP7173ft9/9rrPe/e619yfW02xvBZwATAF2BkYD25Uw3wCfA58ATwGPS/pxfbZQu862dwFuAk4CNmpz3V/Ao8Blkj5uZ82wgGxn82uAi4BNKkE/BFYAXwBhJhamdgD2A8ZWfP8A5ieOpIBsaUMCsr0l8AhweInwJ3AnsETSO0MFtr03MBU4B9i4+L4AnCzpp1ZrWwKyPQp4DtirLH4WOE9SmGnbbIep24Ejy6J3gUmSvm4WpCkg2xsCzwMHl0W3ArMk/dM2koqj7Q2ABcAF5fFLwBGS1jXGawUo5z2rON8o6YpOgDSusX0DcHl5vkBS8rLOBgGyvS/wZvF6EjixU2aaAApTjwHHlXfjJa2s+jUD9AxwNJD6MVbSml6wU4thexsgeZh6tlzS5JaAbB8AvFocLpE0r5dgKqAuBuaW/ydIeq32ro4h24tyk4Bcy1GSUj96brY3Bb4CUlYWSZrRCtCnwI7AvZLO7DmS+pt3D3AGsFrSToMA2U69WVVeTJG0bIQBHVv0LtuMk5T6xMCR2T4FeKiAGC0pkjBiZjsSEyGOnSrp4UZAKVoLgbWSthgxJPXH9jOwOTBTUopvHUMBE1DRq6eBl4E7JP3eS3Aloc8FJgLHFJ1bKOnCAUC2pwO3AZGMqr0HHC/po16Asr0r8ASwR0O8SMgMSXfJdpC+CKSKrgWiyNsD+5dFqUsTu63WRc/C+oQS93UgAntYObbo5KEBlM4uGf8t0Ccp3R62Q2EEMTZZ0vJuWLKd6h8ViEWobyn7pOt8A9gWWBZA3wEp59dLuqq6qe38gjRdsyXN6RJQYl+XZk5SWpsBs53YVwJrAsjlzTRJixscI7IR28WSpnUJ6G7gbGClpPEN++R53vO/BJRrnr45WR4tq1KZNnVco950wlRFJ1dJSntb3ef8csvXhaG3gH2ApZJOr3nZTpKlkqYfni6pn9JOzfZMIIkcwR4jKZeo32w/CJwGvB9AGW0uLe+SeEui9GEFOBBIjdhT0gedgimb7gakrqXWvQKElUwrZwHXltg3B9BmRVQzdzWzOZJmdwOmwkT1xzeGTPHt6xdX22MKM5MqXsmtAJnXbVFsyJfEzImEiJploJgqaXVV7fP37uWap21dIen7XjDTGMN2Bs+ry/O6+jfs5NprQLaTvA8UYU8uHSLp79o+PQVUJt3fJOW4B5ntHFVGoejmD7ndktKlDlhPANmOHt0HHFSu9XxJkYJ+K91oBoajyqOUk+hjf5c4EoCi3H0NsdNbfQmktuWLSViJBUTA1LrF3gKyne9Dn7WRa7+U0Se39tdW/l0fme10A00/HJRNw1K+oOQ2ZfQZ0roGVHKkNu0OpE1hYynwtqRaRzEcnv966mE9h3CwvXUZENLo5fjmSrq/k5j/AsDar2Ghimc4AAAAAElFTkSuQmCC"
            class="mr10 w-18 h-18"
          />客服电话：400-606-8358
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAzBJREFUSEvFl1uIlWUUhp+XwjLMoAzTDCqiIBKkxKs0UNMiAtOruigquwiP3VROIgqaiDemBGEHqgvrIoqg84lKbyy8kCTIUzemRaZ0sJPWG++wNvyNs6c9M9tmwWb/8/9rr/X837fWu76R7bOAx4CFwGWMjB0CngbWyfYK4PGR4Tgt64oAHQSuqEe/A8uBb/4nwEuBTcC5le9ggP4G1AD4GpgtKaBnzGxfCXzQWIzkcoBcWXcBN9T1YeBmSV+eCSLb1wLvAxP75m4C3QTcUVsWv6PALZIC2jWzfT3wLjCugm4GXgE+zd9NoBmSttteBawp55+A2yTt6AaR7RuBN4GxFW+NpNV1f3u/QL0baS+tYktt/QrMl5S3GrLZngu8CpyXFMBDkp6ofAFtD1ROdwPPAdGpP4E7JSXgoM32fOAlYBTwF3C/pBdagf5zhRqO84CXgXMq0H2SXhwMke3mi/1RL/ZaM0bHQLVSM4HXgTG11EskPdkJlO1FwJaSlV+AeZI+7PvbQQEV1DTgbeDCCtYjaf1AUH0mwHHgVkk7+/vNoIEK6jrgPWBCBd0g6dE2CQLbenYEmCNpT7sXGCrQBQWU1WrZU8BiSSnUdGcaIFv0YMPnswL6sWtAti8qMWspeYozhR6L/D9Q11uj8HXd9Im4zpX0w7C3zPb4kvnJFWwl8FYBXlz3WuOnNRO/j8rXZ135fFHj6LshF7XtTON0xTXVYcskZUuyPVeVTk3vkyCqfq+k/eW3GMh4COxXwCxJ/zpNdFRDti8HPqppnBpZKOn5ZnLbSZIZOKXu7wY+ltRasd7btu8Bni2RzWkiUPluPR9YqW1fXSszCTgJ3CUpA3DIZnsBsK3UOifEQO0t4PZAttPeORpcAvwGLJAUDRq22U5dZfyMBlJLOXftabtlwIlq7XTVz8Dtkj4ZNkkjgO0ZwBvA+UC6bk4N3NOG6yNADxC9OVZnoc+7CdOomanAO0BePPqUM/2GPO/vCPtttWdbZe0GZJVGU/l7yylAB4Ccb2OngIeBfd1I2kGMSMdG4Ozy7T3kZ+YMOCg7CNwtl54AZf5EffOPYtp8JCwy8Ayw9h99Tpp3IppnEwAAAABJRU5ErkJggg=="
            class="w-18 h-18"
            style="margin: 0px 12px 0px 70px"
          />邮箱：jimmy@speedyeta.com
        </div>
        <div class="flex align-center">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAABVBJREFUWEedmGnMXVMUhp/XWG2qMRepKTG1VA2NFjE3hlYlUlP5Iab4g9YcNISaWjW0P8T0Q8yiKKFFiYSqFDHXrCLmoYgpxiVv7XPte+45957vW8mX3LP3Xnu/e613DfsTfZSIWAnYBRgP7AVsDGwIBPAl8BnwDPAo8JIkjzcWNV0ZEV57OHAxsG1DvaVp/f1NgTUCFBHDgHuBsSUgvv3XyTLeayiwHlDe93ngCEm2XlfpCSgi9gTmAutmOz0H3AIskPRVfkJEbAAcBJwI7J7NfQMcJsm6tdIVUETsADwLDE47fOKDJD3Z66aej4gDgJsBW9jyI7CHpDfr9GsBRcQ6wOvARknZRJ0k6bsmYIo1EWHL2sK2tOVTYKSk76v26QboBuCUpPSKI0rST+VNImIgsHkaXybp14o1ayZLj0xzcySd1hhQRAwH3gAc4r8DIyR9WOKKCXyNeQGsnua89kFgqiSngJZExNZpz1WBv4Dhkt4vg6q0UERcCZybFs+QVPxeMZS4cTewVo377I7JkhaUQF0LTEljl0i6qCkgW2OLtHhLSR9knHAStPXMsW5irm0v6YtMdwRQEHqpJH+3SYeFEpm/Tas6lCLiLuDobJdFwJyUqU91FGVz90jK19q6H2WcGyjptxxRFSCH+qtp0SOSJmY3NKdMbBPZ8hawk6Q/kitXA14GtkvzPmywpL+zPRYC+6Xvrco8qgK0P1DkmdskHZdttj6QJ8KZks4p8eQqIB8bmifPiHAKcCBYxkp6oZeFdnZRTIsWSWq5ICIGAA7r4iJtFkxWmgcUVnVpGZS7JSLMv8KCjSzkWuT6ZFkuqY28EeEb7ZrdytaYnb6dW2Zkc0sktdZGxCrAL4Bd+ycwpCeH0i3fAZw3LKMkvZa5bV/APMjd7c0tzjGtpcA4SU9luvsAT6fvxZJ2y93l33V56ArgvLT4YUmH5ooRUeZJeV9/V/HLdbGgwNmSrm4KaBPA4blyUuggX0QcA1xfkY+cf6ZIuqN0iYNT0+Zh83CYpOWNACW3zQLOSApvmzflWhYRQ4ADs4bNrp4vyVX9f9/9V2CdDnxRywWSLq8ya7fiOihV+yJjP5T6mT61pInITwDmj8V8HC2p4F0brib9kLu9IhFOkzS96mZ1YxHhAjw1zf/gfrxcqHPdJh2jU7/LheUf4BBJjzUBFRGTgTsz3QmS5nfT7Qko8cnRcGZ2S5u8VXCrDoiIUYCtu0Yv3vTJQgmQo808cA6yuIaNkfRzDRgnU2f7zdL8A6nb7Mm/RhZKoBwpPmTTdMhcSZPKgCLC4N0HuSZa/BRyhFaCL+s3BpRA7Qi43SjccL4kJ9GWRMRM4Kw04PC3ezs6wzoeVVV7P10uBeZJcuJrk4g4Frg9DZrk44vOMCKOAtxJWjw3UZJfsDlgW9BEd3N2gqQl+XwboIhYG3g3vcGcJwZI8sZlUHkr6nZ1tKs6sLhXiogI57WiP/849datJq0M6CbgpHT645KchTskJTv3THunSbelBlS8PmqTaPrfwItu7JLuZZIuLA5pAYqIMSlMPeYO0P3we3W+jgi3KSZ5UQ6KpZVlpuQ2tyS2ZsdZKwClyPDmzh2W6ZKm1YEpxiPCJHdEuZO0+GXrlqP2IpnujcDJ6XuhpHH+XQA6HbguTS5L77C25ruLpfw+m5DeWm5VOip4jdtzvnrJkZLuU0T4WeMq7delxem9LTJ6Waq/8xFxPHBr0v8c2MaAHIKuOYW4n3FGLf4cZf39bb1u+j7TEVrILAOyieteoP29fH/1lhuQw9z/VPCLwu8u88p/ff3dH538DOe92f8CneUYSF1s+wUAAAAASUVORK5CYII="
            class="mr10 w-18 h-18"
          />公司地址：青岛市市北区龙城路30号一单元2004室
        </div>
      </div>
      <div class="rightInfo">
        <div
          class="flex align-center font400"
          style="font-size: 1rem"
        >
          <div class="ml-30" data-v-63e6cccc="">
            <el-popover placement="top-start" trigger="hover" class="qrcode">
              <img
                src="@/assets/qrcode_gzh.jpg"
                class="codegzhimg img" slot="reference"
              />
              <img
                src="@/assets/qrcode_gzh.jpg"
                class="codegzhimg img"
              />
            </el-popover>
            <div class="text-center mt-5">服务号</div>
          </div>
          <div class="ml-30" data-v-63e6cccc="">
            <el-popover placement="top-start" trigger="hover" class="qrcode">
              <img
                src="@/assets/qrcode.png"
                class="codeimg img" slot="reference"
              />
              <img
                src="@/assets/qrcode.png"
                class="codeimg img"
              />
            </el-popover>
            <div class="text-center mt-5">小程序</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mainInfo" v-if="language == 2">
      <div class="leftInfo">
        <div class="font-bold leftInfoTitle">Contact info</div>
        <div class="flex align-center" style="margin: 1rem 0">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAABGpJREFUWEfNmGmsnWMUhZ8lpkQMIVSiNUT5gUpEXVFqbFBqlvCTSrSGRhUxNoSa0kFFTf1REhGamCmNIEIQqiQqEkJQQ0w1VYxlybp5z/Wdc8+59/SccxP7173ft9/9rrPe/e619yfW02xvBZwATAF2BkYD25Uw3wCfA58ATwGPS/pxfbZQu862dwFuAk4CNmpz3V/Ao8Blkj5uZ82wgGxn82uAi4BNKkE/BFYAXwBhJhamdgD2A8ZWfP8A5ieOpIBsaUMCsr0l8AhweInwJ3AnsETSO0MFtr03MBU4B9i4+L4AnCzpp1ZrWwKyPQp4DtirLH4WOE9SmGnbbIep24Ejy6J3gUmSvm4WpCkg2xsCzwMHl0W3ArMk/dM2koqj7Q2ABcAF5fFLwBGS1jXGawUo5z2rON8o6YpOgDSusX0DcHl5vkBS8rLOBgGyvS/wZvF6EjixU2aaAApTjwHHlXfjJa2s+jUD9AxwNJD6MVbSml6wU4thexsgeZh6tlzS5JaAbB8AvFocLpE0r5dgKqAuBuaW/ydIeq32ro4h24tyk4Bcy1GSUj96brY3Bb4CUlYWSZrRCtCnwI7AvZLO7DmS+pt3D3AGsFrSToMA2U69WVVeTJG0bIQBHVv0LtuMk5T6xMCR2T4FeKiAGC0pkjBiZjsSEyGOnSrp4UZAKVoLgbWSthgxJPXH9jOwOTBTUopvHUMBE1DRq6eBl4E7JP3eS3Aloc8FJgLHFJ1bKOnCAUC2pwO3AZGMqr0HHC/po16Asr0r8ASwR0O8SMgMSXfJdpC+CKSKrgWiyNsD+5dFqUsTu63WRc/C+oQS93UgAntYObbo5KEBlM4uGf8t0Ccp3R62Q2EEMTZZ0vJuWLKd6h8ViEWobyn7pOt8A9gWWBZA3wEp59dLuqq6qe38gjRdsyXN6RJQYl+XZk5SWpsBs53YVwJrAsjlzTRJixscI7IR28WSpnUJ6G7gbGClpPEN++R53vO/BJRrnr45WR4tq1KZNnVco950wlRFJ1dJSntb3ef8csvXhaG3gH2ApZJOr3nZTpKlkqYfni6pn9JOzfZMIIkcwR4jKZeo32w/CJwGvB9AGW0uLe+SeEui9GEFOBBIjdhT0gedgimb7gakrqXWvQKElUwrZwHXltg3B9BmRVQzdzWzOZJmdwOmwkT1xzeGTPHt6xdX22MKM5MqXsmtAJnXbVFsyJfEzImEiJploJgqaXVV7fP37uWap21dIen7XjDTGMN2Bs+ry/O6+jfs5NprQLaTvA8UYU8uHSLp79o+PQVUJt3fJOW4B5ntHFVGoejmD7ndktKlDlhPANmOHt0HHFSu9XxJkYJ+K91oBoajyqOUk+hjf5c4EoCi3H0NsdNbfQmktuWLSViJBUTA1LrF3gKyne9Dn7WRa7+U0Se39tdW/l0fme10A00/HJRNw1K+oOQ2ZfQZ0roGVHKkNu0OpE1hYynwtqRaRzEcnv966mE9h3CwvXUZENLo5fjmSrq/k5j/AsDar2Ghimc4AAAAAElFTkSuQmCC"
            class="mr10 w-18 h-18"
          />Customer service：400-606-8358
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAzBJREFUSEvFl1uIlWUUhp+XwjLMoAzTDCqiIBKkxKs0UNMiAtOruigquwiP3VROIgqaiDemBGEHqgvrIoqg84lKbyy8kCTIUzemRaZ0sJPWG++wNvyNs6c9M9tmwWb/8/9rr/X837fWu76R7bOAx4CFwGWMjB0CngbWyfYK4PGR4Tgt64oAHQSuqEe/A8uBb/4nwEuBTcC5le9ggP4G1AD4GpgtKaBnzGxfCXzQWIzkcoBcWXcBN9T1YeBmSV+eCSLb1wLvAxP75m4C3QTcUVsWv6PALZIC2jWzfT3wLjCugm4GXgE+zd9NoBmSttteBawp55+A2yTt6AaR7RuBN4GxFW+NpNV1f3u/QL0baS+tYktt/QrMl5S3GrLZngu8CpyXFMBDkp6ofAFtD1ROdwPPAdGpP4E7JSXgoM32fOAlYBTwF3C/pBdagf5zhRqO84CXgXMq0H2SXhwMke3mi/1RL/ZaM0bHQLVSM4HXgTG11EskPdkJlO1FwJaSlV+AeZI+7PvbQQEV1DTgbeDCCtYjaf1AUH0mwHHgVkk7+/vNoIEK6jrgPWBCBd0g6dE2CQLbenYEmCNpT7sXGCrQBQWU1WrZU8BiSSnUdGcaIFv0YMPnswL6sWtAti8qMWspeYozhR6L/D9Q11uj8HXd9Im4zpX0w7C3zPb4kvnJFWwl8FYBXlz3WuOnNRO/j8rXZ135fFHj6LshF7XtTON0xTXVYcskZUuyPVeVTk3vkyCqfq+k/eW3GMh4COxXwCxJ/zpNdFRDti8HPqppnBpZKOn5ZnLbSZIZOKXu7wY+ltRasd7btu8Bni2RzWkiUPluPR9YqW1fXSszCTgJ3CUpA3DIZnsBsK3UOifEQO0t4PZAttPeORpcAvwGLJAUDRq22U5dZfyMBlJLOXftabtlwIlq7XTVz8Dtkj4ZNkkjgO0ZwBvA+UC6bk4N3NOG6yNADxC9OVZnoc+7CdOomanAO0BePPqUM/2GPO/vCPtttWdbZe0GZJVGU/l7yylAB4Ccb2OngIeBfd1I2kGMSMdG4Ozy7T3kZ+YMOCg7CNwtl54AZf5EffOPYtp8JCwy8Ayw9h99Tpp3IppnEwAAAABJRU5ErkJggg=="
            class="w-18 h-18"
            style="margin: 0px 12px 0px 70px"
          />Email：jimmy@speedyeta.com
        </div>
        <div class="flex align-center">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAABVBJREFUWEedmGnMXVMUhp/XWG2qMRepKTG1VA2NFjE3hlYlUlP5Iab4g9YcNISaWjW0P8T0Q8yiKKFFiYSqFDHXrCLmoYgpxiVv7XPte+45957vW8mX3LP3Xnu/e613DfsTfZSIWAnYBRgP7AVsDGwIBPAl8BnwDPAo8JIkjzcWNV0ZEV57OHAxsG1DvaVp/f1NgTUCFBHDgHuBsSUgvv3XyTLeayiwHlDe93ngCEm2XlfpCSgi9gTmAutmOz0H3AIskPRVfkJEbAAcBJwI7J7NfQMcJsm6tdIVUETsADwLDE47fOKDJD3Z66aej4gDgJsBW9jyI7CHpDfr9GsBRcQ6wOvARknZRJ0k6bsmYIo1EWHL2sK2tOVTYKSk76v26QboBuCUpPSKI0rST+VNImIgsHkaXybp14o1ayZLj0xzcySd1hhQRAwH3gAc4r8DIyR9WOKKCXyNeQGsnua89kFgqiSngJZExNZpz1WBv4Dhkt4vg6q0UERcCZybFs+QVPxeMZS4cTewVo377I7JkhaUQF0LTEljl0i6qCkgW2OLtHhLSR9knHAStPXMsW5irm0v6YtMdwRQEHqpJH+3SYeFEpm/Tas6lCLiLuDobJdFwJyUqU91FGVz90jK19q6H2WcGyjptxxRFSCH+qtp0SOSJmY3NKdMbBPZ8hawk6Q/kitXA14GtkvzPmywpL+zPRYC+6Xvrco8qgK0P1DkmdskHZdttj6QJ8KZks4p8eQqIB8bmifPiHAKcCBYxkp6oZeFdnZRTIsWSWq5ICIGAA7r4iJtFkxWmgcUVnVpGZS7JSLMv8KCjSzkWuT6ZFkuqY28EeEb7ZrdytaYnb6dW2Zkc0sktdZGxCrAL4Bd+ycwpCeH0i3fAZw3LKMkvZa5bV/APMjd7c0tzjGtpcA4SU9luvsAT6fvxZJ2y93l33V56ArgvLT4YUmH5ooRUeZJeV9/V/HLdbGgwNmSrm4KaBPA4blyUuggX0QcA1xfkY+cf6ZIuqN0iYNT0+Zh83CYpOWNACW3zQLOSApvmzflWhYRQ4ADs4bNrp4vyVX9f9/9V2CdDnxRywWSLq8ya7fiOihV+yJjP5T6mT61pInITwDmj8V8HC2p4F0brib9kLu9IhFOkzS96mZ1YxHhAjw1zf/gfrxcqHPdJh2jU7/LheUf4BBJjzUBFRGTgTsz3QmS5nfT7Qko8cnRcGZ2S5u8VXCrDoiIUYCtu0Yv3vTJQgmQo808cA6yuIaNkfRzDRgnU2f7zdL8A6nb7Mm/RhZKoBwpPmTTdMhcSZPKgCLC4N0HuSZa/BRyhFaCL+s3BpRA7Qi43SjccL4kJ9GWRMRM4Kw04PC3ezs6wzoeVVV7P10uBeZJcuJrk4g4Frg9DZrk44vOMCKOAtxJWjw3UZJfsDlgW9BEd3N2gqQl+XwboIhYG3g3vcGcJwZI8sZlUHkr6nZ1tKs6sLhXiogI57WiP/849datJq0M6CbgpHT645KchTskJTv3THunSbelBlS8PmqTaPrfwItu7JLuZZIuLA5pAYqIMSlMPeYO0P3we3W+jgi3KSZ5UQ6KpZVlpuQ2tyS2ZsdZKwClyPDmzh2W6ZKm1YEpxiPCJHdEuZO0+GXrlqP2IpnujcDJ6XuhpHH+XQA6HbguTS5L77C25ruLpfw+m5DeWm5VOip4jdtzvnrJkZLuU0T4WeMq7delxem9LTJ6Waq/8xFxPHBr0v8c2MaAHIKuOYW4n3FGLf4cZf39bb1u+j7TEVrILAOyieteoP29fH/1lhuQw9z/VPCLwu8u88p/ff3dH538DOe92f8CneUYSF1s+wUAAAAASUVORK5CYII="
            class="mr10 w-18 h-18"
          />Company address：Room 2004, Unit 1, No. 30 Longcheng Road, Shibei District, Qingdao. 
        </div>
      </div>
      <div class="rightInfo">
        <div
          class="flex align-center font400"
          style="font-size: 1rem"
        >
          <div class="ml-30" data-v-63e6cccc="">
            <el-popover placement="top-start" trigger="hover" class="qrcode">
              <img
                src="@/assets/qrcode_gzh.jpg"
                class="codegzhimg img" slot="reference"
              />
              <img
                src="@/assets/qrcode_gzh.jpg"
                class="codegzhimg img"
              />
            </el-popover>
            <div class="text-center mt-5">official account</div>
          </div>
          <div class="ml-30" data-v-63e6cccc="">
            <el-popover placement="top-start" trigger="hover" class="qrcode">
              <img
                src="@/assets/qrcode.png"
                class="codeimg img" slot="reference"
              />
              <img
                src="@/assets/qrcode.png"
                class="codeimg img"
              />
            </el-popover>
            <div class="text-center mt-5">applet</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footerbox">
      <!-- <img src="@/assets/midlog.png" alt="" /> -->
      <div class="footertext">Copyright © {{ cutList.reserved }}</div>
      <div class="footertext">{{ cutList.telephone1 }}：400-060-8358</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      language: 1
    }
  },
  computed: {
    cutList () {
      return this.$t('m')
    }
  },
  mounted () {
    if (sessionStorage.getItem('cut')) {
      if (sessionStorage.getItem('cut') === 'zh-CN') {
        this.language = 1
      } else if (sessionStorage.getItem('cut') === 'en-US') {
        this.language = 2
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.mainFooter {
  width: 100%;
  background: #383838;
  position: relative;
  color: #ffffff;
  .mainInfo {
    height: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .leftInfo {
      font-size: 1.2rem;
      .leftInfoTitle {
        font-size: 1.5rem;
      }
    }
    .rightInfo{
      margin-left:10rem;
      .codegzhimg{
        width: 6.125rem;
        height: 6.125rem;
      }
      .codeimg{
        width: 5.375rem;
        height: 6.125rem;
      }
    }
  }
  .footerbox {
    border-top: 0.00521rem solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    .footertext {
      font-size: 1rem;
      color: #ffffff;
    }
  }
}
</style>
