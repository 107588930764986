var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isHover == true ||
    _vm.isHover1 == true ||
    _vm.isHover2 == true ||
    _vm.$route.path == '/onlineQuotation' ||
    _vm.$route.path == '/searchRecord' ||
    _vm.$route.path == '/search' ||
    _vm.$route.path == '/searchDec' ||
    _vm.$route.path == '/orderDec' ||
    _vm.$route.path == '/myorder' ||
    _vm.$route.path == '/about' ||
    _vm.$route.path == '/contact' ||
    _vm.$route.path == '/userinfo'
      ? 'nativeheader'
      : 'header',on:{"mouseleave":function($event){_vm.isHover = false;
    _vm.isHover1 = false;
    _vm.isHover2 = false;}}},[_c('div',{class:_vm.isHover == true ||
      _vm.isHover1 == true ||
      _vm.isHover2 == true ||
      _vm.$route.path == '/onlineQuotation' ||
      _vm.$route.path == '/searchRecord' ||
      _vm.$route.path == '/search' ||
      _vm.$route.path == '/searchDec' ||
      _vm.$route.path == '/orderDec' ||
      _vm.$route.path == '/myorder' ||
      _vm.$route.path == '/userinfo'
        ? 'nativemainnavbar'
        : 'mainnavbar'},[(_vm.language == 1)?_c('img',{attrs:{"src":require("@/assets/top_logo.png"),"alt":""}}):_vm._e(),(_vm.language == 2)?_c('img',{attrs:{"src":require("@/assets/top_logo_en.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"navbar"},[_c('ul',[_c('li',{on:{"mouseenter":function($event){// isHover = true;
            _vm.isHover1 = false;
            _vm.isHover2 = false;}}},[_c('router-link',{staticClass:"normal",attrs:{"to":"/index"},nativeOn:{"click":function($event){return _vm.sendDataToParent.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.cutList.onlineprice))])],1),_c('li',{on:{"mouseenter":_vm.searchenter}},[_c('router-link',{staticClass:"normal",attrs:{"to":"/search"},nativeOn:{"click":function($event){return _vm.sendDataToParent.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.cutList.rengongprice))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHover1),expression:"isHover1"}],staticClass:"subnav"},[_c('div',{staticClass:"minsubnav"},[_c('router-link',{staticClass:"minsubnavbox normal",attrs:{"to":"/search"},nativeOn:{"click":function($event){return _vm.sendDataToParent.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.cutList.inquiry))]),_c('router-link',{staticClass:"minsubnavbox normal",attrs:{"to":"/myorder"},nativeOn:{"click":function($event){return _vm.sendDataToParent.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.cutList.myorder))])],1)])],1),_c('li',{on:{"mouseenter":function($event){_vm.isHover = false;
            _vm.isHover1 = false;
            _vm.isHover2 = false;},"click":_vm.tosearch}},[_vm._v(" "+_vm._s(_vm.cutList.yesterday)+" ")]),_c('li',{on:{"mouseenter":function($event){_vm.isHover = false;
            _vm.isHover1 = false;
            _vm.isHover2 = false;}}},[_c('router-link',{staticClass:"normal",attrs:{"to":"/information"}},[_vm._v(_vm._s(_vm.cutList.industry))])],1),_c('li',{on:{"mouseenter":_vm.aboutenter}},[_c('router-link',{staticClass:"normal",attrs:{"to":"/about"}},[_vm._v(_vm._s(_vm.cutList.aboutus))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHover2),expression:"isHover2"}],staticClass:"subnav"},[_c('div',{staticClass:"minsubnav"},[_c('router-link',{staticClass:"minsubnavbox normal",attrs:{"to":"/about"}},[_vm._v(_vm._s(_vm.cutList.about))]),_c('router-link',{staticClass:"minsubnavbox normal",attrs:{"to":"/contact"}},[_vm._v(_vm._s(_vm.cutList.connect))])],1)])],1),_c('li',{on:{"mouseenter":function($event){_vm.isHover = false;
            _vm.isHover1 = false;
            _vm.isHover2 = false;}}},[_c('router-link',{staticClass:"minsubnavbox normal",attrs:{"to":"/searchRecord"},nativeOn:{"click":function($event){return _vm.sendDataToParent.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.cutList.searchrecord))])],1),_c('li',{on:{"mouseenter":function($event){_vm.isHover = false;
            _vm.isHover1 = false;
            _vm.isHover2 = false;},"click":_vm.addToFavorites}},[_vm._v(" "+_vm._s(_vm.cutList.collect)+" ")])])]),(_vm.user_id == '')?_c('div',{staticClass:"btnbox"},[_c('div',{staticClass:"enrollbtn",on:{"click":_vm.sendData}},[_vm._v(_vm._s(_vm.cutList.enroll))]),_c('div',{staticClass:"loginbtn",on:{"click":_vm.sendDataToParent}},[_vm._v(" "+_vm._s(_vm.cutList.ringup)+" ")])]):_c('div',{staticClass:"userbox",on:{"mouseenter":_vm.inenteruser,"mouseleave":function($event){_vm.show = false}}},[_c('div',{staticClass:"nameimgbox"},[_c('img',{attrs:{"src":_vm.avator,"alt":""}}),_c('div',{staticClass:"username"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"dropdownbox"},[_c('div',{staticClass:"nodiropdownitem",on:{"click":_vm.touserinfo}},[_vm._v(" "+_vm._s(_vm.cutList.personage)+" ")]),_c('div',{staticClass:"nodiropdownitem",on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.cutList.logDown)+" ")])])]),_c('div',{staticClass:"language",on:{"mouseenter":function($event){_vm.isHover = false;
        _vm.isHover1 = false;
        _vm.isHover2 = false;},"click":function($event){_vm.show1 = !_vm.show1}}},[(_vm.language == 1)?_c('span',[_vm._v("简体中文")]):_vm._e(),(_vm.language == 2)?_c('span',[_vm._v("EN")]):_vm._e(),_c('img',{attrs:{"src":require("@/assets/chevron-down.png"),"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show1),expression:"show1"}],staticClass:"dropdownbox"},[_c('div',{class:_vm.language == 1 ? 'diropdownitem' : 'nodiropdownitem',on:{"click":_vm.chineseClick}},[_vm._v(" 简体中文 ")]),_c('div',{class:_vm.language == 2 ? 'diropdownitem' : 'nodiropdownitem',on:{"click":_vm.engClick}},[_vm._v(" EN ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }